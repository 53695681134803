.inputZone
{
    margin: 10px;
}

.inputZone input,
.inputZone select,
.inputZone textarea
{
    border: none;
    background-color:  rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

.inputZoneSubmit label
{
    text-align: center;
    background-color: rgba(0, 162, 255, 0.689);
    color: white;
    width: 100%;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}