/* .User {
  margin-left: 280px;
} */

.section {
  padding: 100px 0;
  position: relative;
}

.gray-bg {
  background-color: #f5f5f5;
}

img {
  max-width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

/* About Me 
---------------------*/
.about-text h3 {
  font-size: 45px;
  font-weight: 700;
  margin: 0 0 6px;
}

@media (max-width: 767px) {
  .about-text h3 {
    font-size: 35px;
  }
}

.about-text h6 {
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .about-text h6 {
    font-size: 18px;
  }
}

.about-text p {
  font-size: 18px;
  max-width: 450px;
}

.about-text p mark {
  font-weight: 600;
  color: #20247b;
}

.about-list {
  padding-top: 10px;
}

.about-list .media {
  padding: 5px 0;
}

.about-list label {
  color: #20247b;
  font-weight: 600;
  width: 88px;
  margin: 0;
  position: relative;
}

.about-list label:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  width: 1px;
  height: 12px;
  background: #20247b;
  -moz-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
  margin: auto;
  opacity: 0.5;
}

.about-list p {
  margin: 0;
  font-size: 15px;
}

@media (max-width: 991px) {
  .about-avatar {
    margin-top: 30px;
  }
}

.about-section .counter {
  padding: 22px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
}

.about-section .counter .count-data {
  margin-top: 10px;
  margin-bottom: 10px;
}

.about-section .counter .count {
  font-weight: 700;
  color: #20247b;
  margin: 0 0 5px;
}

.about-section .counter p {
  font-weight: 600;
  margin: 0;
}

mark {
  background-image: linear-gradient(rgba(252, 83, 86, 0.6), rgba(252, 83, 86, 0.6));
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}

.theme-color {
  color: #fc5356;
}

.dark-color {
  color: #20247b;
}

.conteneurExperienceFormation {
  margin-top: 10px;
  margin-bottom: 10px;
}

.experienceProfessionnels
{
  margin-bottom: 10px;
}

@media (min-width: 879.7px){
  .conteneurExperienceFormation {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .experienceProfessionnels
  {
    min-width: 50%;
    max-width: 50%;
  }
}

.headerDisposition
{
  display: flex;
  justify-content: space-between;
}

.headerDispositionDeux span{
  margin-right: 10px;
  font-size: 18px;
  cursor: pointer;
}

.elementExperience {
  margin: 10px;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(212, 212, 212);
}

.pointRepere
{
  position: absolute;
  left: -20px;
}

.elementExperiencePoste{
  font-size: 18px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.elementExperienceEntreprise span
{
  font-size: 14px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 5px;
}

.pointEntreprise
{
  font-size: 4px;
  margin-right: 5px;
}

.elementExperienceDuree span{
  margin-right: 5px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 14px;
  font-weight: 200;
}

.elementExperienceLieu
{
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 100;
}

.elementExperienceCompetence header
{
  margin-top: 10px;
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
}

.descriptionCompetence
{
  margin-left: 30px;
  font-size: 14px;
}