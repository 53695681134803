.conteneurReponseChallenge
{
    margin-left: 5%;
    margin-right: 5%;
}

@media (min-width: 900px)
{
    .conteneurReponseChallenge
    {
        margin-left: 10%;
        margin-right: 10%;
    }   
}