@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --c-text-primary: #282a32;
  --c-text-secondary: #686b87;
  --c-text-action: #404089;
  --c-accent-primary: #434ce8;
  --c-border-primary: #eff1f6;
  --c-background-primary: #ffffff;
  --c-background-secondary: #fdfcff;
  --c-background-tertiary: #ecf3fe;
  --c-background-quaternary: #e9ecf4;
}

.Dashboard {
  line-height: 1.5;
  min-height: 100vh;
  font-family: "Be Vietnam Pro", sans-serif;
  background-color: var(--c-background-secondary);
  color: var(--c-text-primary);
}

.Dashboard img {
  display: block;
  max-width: 100%;
}

.Dashboard :focus {
  outline: 0;
}

.Dashboard .responsive-wrapper {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.Dashboard .header {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid var(--c-border-primary);
  background-color: var(--c-background-primary);
}

.Dashboard .header-content {
  display: flex;
  align-items: center;
}
.Dashboard .header-content > a {
  display: none;
}
@media (max-width: 1200px) {
.Dashboard  .header-content {
    justify-content: space-between;
  }
 .Dashboard  .header-content > a {
    display: inline-flex;
  }
}

.Dashboard .header-logo {
  margin-right: 2.5rem;
}
.Dashboard .header-logo a {
  display: flex;
  align-items: center;
}
.Dashboard .header-logo a div {
  flex-shrink: 0;
  position: relative;
}
.Dashboard .header-logo a div:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0;
  overflow: hidden;
  height: 50%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.Dashboard .header-navigation {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1200px) {
    .Dashboard .header-navigation {
    display: none;
  }
}

.Dashboard .header-navigation-links {
  display: flex;
  align-items: center;
}
.Dashboard .header-navigation-links a {
  text-decoration: none;
  color: var(--c-text-action);
  font-weight: 500;
  transition: 0.15s ease;
}
.Dashboard .header-navigation-links a + * {
  margin-left: 1.5rem;
}
.Dashboard .header-navigation-links a:hover, .header-navigation-links a:focus {
  color: var(--c-accent-primary);
}

.Dashboard .header-navigation-actions {
  display: flex;
  align-items: center;
}
.Dashboard .header-navigation-actions > .avatar {
  margin-left: 0.75rem;
}
.Dashboard .header-navigation-actions > .icon-button + .icon-button {
  margin-left: 0.25rem;
}
.Dashboard .header-navigation-actions > .button + .icon-button {
  margin-left: 1rem;
}

.Dashboard .button {
  font: inherit;
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  height: 40px;
  border-radius: 8px;
  line-height: 1;
  border: 2px solid var(--c-border-primary);
  color: var(--c-text-action);
  font-size: 0.875rem;
  transition: 0.15s ease;
  background-color: var(--c-background-primary);
}
.Dashboard .button i {
  margin-right: 0.5rem;
  font-size: 1.25em;
}
.Dashboard .button span {
  font-weight: 500;
}
.Dashboard .button:hover, .Dashboard .button:focus {
  border-color: var(--c-accent-primary);
  color: var(--c-accent-primary);
}

.Dashboard .icon-button {
  font: inherit;
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: var(--c-text-action);
  transition: 0.15s ease;
}
.Dashboard .icon-button i {
  font-size: 1.25em;
}
.Dashboard .icon-button:focus, .Dashboard .icon-button:hover {
  background-color: var(--c-background-tertiary);
  color: var(--c-accent-primary);
}

.Dashboard .avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
}

.Dashboard .main {
  padding-top: 3rem;
}

.Dashboard .main-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.Dashboard .main-header h1 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.25;
}
@media (max-width: 550px) {
    .Dashboard .main-header h1 {
    margin-bottom: 1rem;
  }
}

.Dashboard .search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 340px;
}
.Dashboard .search input {
  font: inherit;
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em 0 36px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid var(--c-border-primary);
  color: var(--c-text-action);
  font-size: 0.875rem;
  transition: 0.15s ease;
  width: 100%;
  line-height: 1;
}
.Dashboard .search input::-moz-placeholder {
  color: var(--c-text-action);
}
.Dashboard .search input:-ms-input-placeholder {
  color: var(--c-text-action);
}
.Dashboard .search input::placeholder {
  color: var(--c-text-action);
}
.Dashboard .search input:focus, .Dashboard .search input:hover {
  border-color: var(--c-accent-primary);
}
.Dashboard .search button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: transparent;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25em;
  color: var(--c-text-action);
  padding: 0;
  height: 40px;
}

.Dashboard .horizontal-tabs {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
}
@media (max-width: 1000px) {
  .Dashboard .horizontal-tabs {
    scrollbar-width: none;
    position: relative;
  }
  .Dashboard .horizontal-tabs::-webkit-scrollbar {
    display: none;
  }
}
.Dashboard .horizontal-tabs a {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  height: 48px;
  padding: 0 0.25rem;
  font-weight: 500;
  color: inherit;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  transition: 0.15s ease;
}
.Dashboard .horizontal-tabs a:hover, .Dashboard .horizontal-tabs a:focus, .Dashboard .horizontal-tabs a.active {
  color: var(--c-accent-primary);
  border-bottom-color: var(--c-accent-primary);
}
.Dashboard .horizontal-tabs a + * {
  margin-left: 1rem;
}

.Dashboard .content-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  margin-top: -1px;
  border-top: 1px solid var(--c-border-primary);
}

.Dashboard .content-header-intro h2 {
  font-size: 1.25rem;
  font-weight: 600;
}
.Dashboard .content-header-intro p {
  color: var(--c-text-secondary);
  margin-top: 0.25rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
    .Dashboard .content-header-actions a:first-child {
    display: none;
  }
}

.Dashboard .content {
  border-top: 1px solid var(--c-border-primary);
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
}

.Dashboard .content-panel {
  display: none;
  max-width: 280px;
  width: 25%;
  padding: 2rem 1rem 2rem 0;
  margin-right: 3rem;
}
@media (min-width: 800px) {
    .Dashboard .content-panel {
    display: block;
  }
}

.Dashboard .vertical-tabs {
  display: flex;
  flex-direction: column;
}
.Dashboard .vertical-tabs a {
  display: flex;
  align-items: center;
  padding: 0.75em 1em;
  background-color: transparent;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  color: var(--c-text-action);
  transition: 0.15s ease;
}
.Dashboard .vertical-tabs a:hover, .Dashboard .vertical-tabs a:focus, .Dashboard .vertical-tabs a.active {
  background-color: var(--c-background-tertiary);
  color: var(--c-accent-primary);
}
.Dashboard .vertical-tabs a + * {
  margin-top: 0.25rem;
}

.Dashboard .content-main {
  padding-top: 2rem;
  padding-bottom: 6rem;
  flex-grow: 1;
}

.Dashboard .card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
  row-gap: 1.5rem;
}
@media (min-width: 600px) {
    .Dashboard .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
    .Dashboard .card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.Dashboard .card {
  background-color: var(--c-background-primary);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 5px 15px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.Dashboard .card-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.25rem 1rem 1.25rem;
}
.Dashboard .card-header div {
  display: flex;
  align-items: center;
}
.Dashboard .card-header div span {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.Dashboard .card-header div span img {
  max-height: 100%;
}
.Dashboard .card-header div h3 {
  margin-left: 0.75rem;
  font-weight: 500;
}

.Dashboard .toggle span {
  display: block;
  width: 40px;
  height: 24px;
  border-radius: 99em;
  background-color: var(--c-background-quaternary);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  transition: 0.15s ease;
}
.Dashboard .toggle span:before {
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  top: 3px;
  height: 18px;
  width: 18px;
  background-color: var(--c-background-primary);
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  transition: 0.15s ease;
}
.Dashboard .toggle input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.Dashboard .toggle input:checked + span {
  background-color: var(--c-accent-primary);
}
.Dashboard .toggle input:checked + span:before {
  transform: translateX(calc(100% - 2px));
}
.Dashboard .toggle input:focus + span {
  box-shadow: 0 0 0 4px var(--c-background-tertiary);
}

.Dashboard .card-body {
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
}

.Dashboard .card-footer {
  margin-top: auto;
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--c-border-primary);
}
.Dashboard .card-footer a {
  color: var(--c-text-action);
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
}

.Dashboard::-webkit-scrollbar {
  width: 12px;
}
.Dashboard::-webkit-scrollbar-thumb {
  background-color: var(--c-text-primary);
  border: 4px solid var(--c-background-primary);
  border-radius: 99em;
}