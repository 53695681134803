.sideBarsCompte
{
    position: fixed;
    right: 20px;
    width: 40vh;
    margin-top: 60px;
    text-align: center;
    background-color: #F4F7FE;
    z-index: 99;
    transition: all 3s ease-in-out;
}


@media (min-width: 1300px)
{
    .sideBarsCompte
{
    position: fixed;
    right: 0;
    width: 100%;
    justify-content: center;
    margin-top: 60px;
    text-align: center;
    background-color: #F4F7FE;
    z-index: 99;
    transition: all 3s ease-in-out;
}
.elementIcon
{
    display: none;
}

.elementSidebar
{
    display: flex;
    justify-content: right;
    width: 100%;
}
}

.boutonFermer
{
    color: rgba(255, 68, 0, 0.763);
    font-size: x-large;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

.imageSidebar
{
    padding: 10px;
}

.conteneurElementIcon
{
    display: flex;
    align-items: center;
    color: rgb(76, 158, 231);
    text-align: center;
    margin: 15px;
    cursor: pointer;
}

.elementIcon
{
    font-size: x-large;
    padding: 5px;
}

.elementName
{
    font-size: large;
}

.lesLogo
{
    position: absolute;
    bottom: 10px;
    /* display: flex; */
    /* justify-content: center; */
}

.lesLogo img
{
    margin: 10px;    
}

.conteneurHeader
{
    position: fixed;
    display: flex;
    top: 5px;
    width: 100%;
    height: 60px;
    background-color: #F4F7FE;
    box-shadow: 0px 0px 1px 1px rgba(205, 189, 189, 0.1);
    z-index: 99;
}

.userSearchContainer
{
    position: absolute;
    display: flex;
    align-items: center;
    right: 5px;
    top: 5px;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.userSearchContainer span
{
    /* font-size: 25px; */
    cursor: pointer;
}

.userIconHeader
{
    color: rgb(76, 158, 231);
}

.searchEntete{
    display: none;
}

.iconSearch
{
    color: rgba(0,0,0,0.4);
}

@media (min-width: 650px){

    .userSearchContainer
    {
        right: 30px;
    }
.searchEntete{
    display: initial;
}

.iconSearch
{
    position: absolute;
    right: 90px;
}
}

@media (min-width: 900px) {

    /* .conteneurComposantsActifs
    {
        margin-left: 10%;
        margin-right: 10%;
    }
     */
}

