.tailleMaxImages {
    max-height: 300px;
}

.titreAlumnis {
    margin-top: -20px;
}

.cardAlumnis {
    margin: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

@media (min-width: 980px){
    .cardAlumnis {
        margin-left: 10%;
        margin-right: 10%;
    } 
}

.titreParcours
{
display: flex;
 justify-content: space-between 
}