.connexionPage
{
    /* background-color: rgba(0, 162, 255, 0.689); */
    width: 100%;
    height: 100vh;
    position: relative;
}

.iconDeux
{
    width: 500px;
    margin-bottom: -100px;
}

.conteneurForm
{
    border: 1px solid rgba(0, 162, 255, 0.689);
    box-shadow: 1px 1px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
    margin: 10px;
    min-width: 390px;
    max-width: 390px;
    text-align: left;
    font-weight: 300;
    margin-top: 10px;
    padding-top: 30px;
}

.conteneurInput, .conteneurInputAlumnisChoix
{
    margin: 10px;
    margin-left: 20px;
}

.elementInputChoix input
{
    transform: scale(1.5);
    margin-left: 30px;
}

.conteneurInput input, .conteneurInput select
{
    width: 90%;
    margin: 10px;
    margin-top: 0;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.conteneurSubmit
{
    margin: 10px;
    padding: 5px;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    border: 1px solid rgba(0, 162, 255, 0.689);
    border-radius: 100px;
    width: 120px;
    color: rgba(0, 162, 255, 0.689);
    cursor: pointer;
}

.boutonRetourInscrire
{
    margin: 10px;
    padding: 5px;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    border: 1px solid orange;
    border-radius: 100px;
    width: 120px;
    color: orange;
    cursor: pointer;
}

.motDePasseOublie
{
    font-weight: 100;
    font-size: 12px;
    margin-top: -15px;
    text-align: right;
    margin-right: 50px;
    margin-bottom: 20px;
}

@media(max-width:340px) {
.conteneurForm
{
    min-width: 300px;
    max-width: 300px;
}

.pageImage
{
    width: 300px;
}

}

@media  (min-width:340px) and (max-width:640px) {
.conteneurForm
{
    min-width: 320px;
    max-width: 320px;
}

.pageImage
{
    width: 350px;
}

}


@media (min-width:1024px)
{
.connexionPage
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.conteneurForm
{
    margin-top: 0;
    /* margin-left: -150px; */
}

.pageImage
{
    width: 700px;
}
}