:root{
    --white:#FFF;
    --black:#000;
    --dark:#1E1E1E;
    --gray:rgba(1,1,1,0.6);
    --lite:rgba(255,255,255,0.6);
    --primary:#002347;
    --secondary:#32dbfdd3;
}


/***************************
            DEFAULT
****************************/
footer{
    overflow-x:hidden !important;
    font-family: 'Poppins', sans-serif;
}

a{
    text-decoration:none !important;
    /* min-width: fit-content; */
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}

a, button{
    transition:0.5s;
}

a, p{
    font-size:14px;
}

h1, h2, h3, h4, h5, h6{
    color:var(--primary);
    font-weight:600;
}

a, button, input, textarea, select{
    outline:none !important;
}

fieldset{
    border:0;
}

.title{
    color:var(--primary);
}

.flex, .fixed_flex{
    display:flex;
    justify-content: space-around;
}

.flex-content{
    /* width:100%; */
    position:relative;
}

.padding_1x{
    padding:10px;
}

.padding_2x{
    padding:2rem;
}

.padding_3x{
    padding:3rem;
}

.padding_4x{
    padding:1rem;
}

.btn{
    padding:0.8rem 2rem;
    border-radius:5px;
    text-align:center;
    font-weight:500;
    text-transform:uppercase;
}

.btn_1{
    border:1px solid var(--primary);
    background-color:var(--primary);
    color:var(--secondary);
}

.btn_1:hover{
    background-color:transparent;
    color:var(--primary);
}

.btn_2{
    border:1px solid var(--secondary);
    background-color:var(--secondary);
    color:var(--primary);
}

.btn_2:hover{
    border:1px solid var(--primary);
    background-color:var(--primary);
    color:var(--secondary);
}

@media (max-width:920px){
    .flex{
        flex-wrap:wrap;
    }
    
    .padding_1x, .padding_2x, .padding_3x, .padding_4x{
        padding:1rem;
    }
  
    .btn{
        padding:0.5rem 1rem;
    }
    
    a, p{
        font-size:12px;
    }
}



/***************************
               FOOTER
****************************/
footer{
    background-color:var(--primary);
    color:var(--lite);
}

footer h3{
    color:var(--white);
    margin-bottom:1.5rem;
    font-size: 18px;
}

footer a{
    color:var(--lite);
    display:block;
    margin:10px 0;
}

footer a:hover{
    color:var(--white);
}

footer fieldset{
    padding:0;
}

footer fieldset input{
    background-color:#334f6c;
    border:0;
    color:var(--lite);
    padding:1rem;
}

footer fieldset .btn{
    border-radius:0;
    border:0;
}

footer fieldset .btn_2:hover{
    background-color:var(--secondary);
    border:0;
    color:var(--primary);
}

footer .flex:last-child{
    align-items:center;
}

footer .flex:last-child .flex-content:last-child{
    text-align:right;
}

footer .flex:last-child p{
    color:var(--white);
}

footer .flex:last-child a{
    /* width:40px; */
    display:inline-block;
    background-color:#334f6c;
    color:var(--white);
    padding:0.5rem;
    margin-right:3px;
    text-align:center;
}

footer .flex:last-child a:hover{
    background-color:var(--secondary);
    color:var(--gray);
}

@media (max-width:1100px){
    footer .flex:first-child{
        flex-wrap:wrap;
    }
    
    /* footer .flex:first-child .flex-content{
        flex: 1 1 40%;
    } */
}

@media (max-width:920px){
    footer .flex:last-child .flex-content:last-child{
        text-align:left;
    }
}

@media (max-width:320px){
    footer .flex:first-child .flex-content{
        flex:1 1 100%;
    }
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
    overflow: hidden;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
  }
  
  .gallery .gallery-item img {
    transition: all ease-in-out 0.4s;
    min-width: 290px;
    max-width: 290px;
    min-height: 220px;
    max-height: 220px;
  }
  
  .gallery .gallery-item:hover img {
    transform: scale(1.1);
  }

  .rowVideo 
  {
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .videosFacebook
  {
    margin: 10px;
  }

  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info {
    width: 100%;
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #1977cc;
    float: left;
    width: 44px;
    height: 44px;
    background: #d6e9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2c4964;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #4b7dab;
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #1977cc;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  /* .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  } */
  
  /* .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
   */
  .contact .php-email-form button[type=submit] {
    background: #1977cc;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #1c84e3;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  